import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import OktaAuth from '@okta/okta-auth-js';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import {
  OktaAuthModule,
  OktaCallbackComponent,
  OKTA_CONFIG,
} from '@okta/okta-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from './environment/environment';
import { AuthInterceptor } from './auth.interceptor';
import { OktaSignInGuard } from './okta-sign-in.guard';
import { SvgSymbolsComponent } from './components/svg-symbols/svg-symbols.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HmeConsentComponent } from './components/hme-consent/hme-consent.component';
import { TriggerSurveyComponent } from './components/trigger-survey/trigger-survey.component';
import { PatientSearchComponent } from './components/patient-search/patient-search.component';
import { MquiIntegrationComponent } from './components/mqui-integration/mqui-integration.component';

const oktaAuth = new OktaAuth({
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/login/callback',
});

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [OktaSignInGuard],
  },
  {
    path: 'patient-search',
    component: PatientSearchComponent,
  },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SvgSymbolsComponent,
    HmeConsentComponent,
    TriggerSurveyComponent,
    PatientSearchComponent,
    MquiIntegrationComponent,
  ],
  imports: [
    OktaAuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(routes),
    NgbModule,
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
