import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { openModal, closeModal } from 'src/app/utils/common_modal_functions';

@Component({
  selector: 'app-mqui-integration',
  templateUrl: './mqui-integration.component.html',
  styleUrl: './mqui-integration.component.scss',
})
export class MquiIntegrationComponent implements OnInit {
  mquiUrl: SafeResourceUrl | null = null;

  constructor(
    private elementRef: ElementRef,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.sharedService.mquiOpen$.subscribe((url: string) => {
      this.mquiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.handleMquiOpen();
    });
  }

  handleMquiOpen() {
    this.sharedService.setMquiClose(false);
    openModal('MquiModal', [], this.elementRef);
  }

  closeModal(id: string, drpdownIds: string[]) {
    this.sharedService.setMquiClose(true);
    closeModal(id, drpdownIds, this.elementRef);
  }
}
